import { PayloadAction } from "@reduxjs/toolkit"
import { TAppStore, TAppStoreMode, TAppStoreModeEvent } from "../../types"



const appChangeMode = (state: TAppStore, action: PayloadAction<TAppStoreModeEvent>) =>{

    let resultMode:TAppStoreMode = state.mode

    switch(action.payload){
        case 'event_loading_from_local_file' :
            if(resultMode === 'loading'){

                resultMode = 'loading_from_local_file'
            }
            break
        
        case 'event_loading_from_url' :
            if(resultMode === 'loading'){

                resultMode = 'loading_from_url'
            }
            break

        case 'event_loading_finish' :
            if(resultMode === 'loading_from_url' || resultMode === 'loading_from_local_file'){

                resultMode = 'play_view'
            }
            break

        case 'event_loading_finished_no_animation' :
            if(resultMode === 'loading_from_url'){

                resultMode = 'stoped'
            }
            break

        case 'event_play_view' :
            if(
                resultMode === 'loading_from_local_file' ||
                resultMode === 'loading_from_url' ||
                resultMode === 'changing_view' ||
                resultMode === 'stoped'
            ){
                resultMode = 'play_view'
            }
            break
        case 'event_pause_before_next_view' :
            if(resultMode === 'play_view'){
                resultMode = 'pause_before_next_view'
            }
            break
        
        case 'event_change_view' :
            if(resultMode === 'pause_before_next_view'){
                resultMode = 'changing_view'
            }
            break

        case 'event_stop' :
            if(
                resultMode === 'loading_from_url' ||
                resultMode === 'pause_before_next_view' ||
                resultMode === 'changing_view' ||
                resultMode === 'play_view'
            ){
                resultMode = 'stoped'
            }
            break

        default:
            break
    }
    
    state.mode = resultMode 
}

export default appChangeMode
