import JSZip from "jszip"
import { Dispatch, SetStateAction } from "react"
import { TTeethStepsPosition } from "../ModelLoader/ModelLoader"
import unzipSmilewrapper from "../ModelLoader/unzipSmilewrapper"
import unzipObj          from "../ModelLoader/unzipObj"
import { TSteppedModelData, TTeethModelData } from "../types"
import detectZipFormat from "../ModelLoader/detectZipFormat"
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader"
import { mergeBufferGeometries } from "../ModelLoader/BufferGeometryUtils"
import * as THREE from 'three'
import { useStoreAction } from "../Store/Hook/useStoreAction"
import appSliceReducer from "../Store/Reducer/appReducer"

type TZipFileLoaderProps = {
    setGingivaModeGeometry          : Dispatch<SetStateAction<TSteppedModelData>>
    setTeethModelData               : Dispatch<SetStateAction<TTeethModelData>>
    setTeethModelStepTransformation : Dispatch<SetStateAction<TTeethStepsPosition[][]>>
    setSmilewrapperInfo             : Dispatch<SetStateAction<string | undefined>>
    onGlobalError                   : (errorString:string) => void
    setTeethModelGeometry           : React.Dispatch<React.SetStateAction<TSteppedModelData>>
    setGingivaModelData             : React.Dispatch<React.SetStateAction<TSteppedModelData>>
    onFinish?                       : () => void
}

type TObjData = {
    name: string
    data: string
}

const ZipFileLoader = (props:TZipFileLoaderProps) => {
    const {
        setGingivaModeGeometry,
        setSmilewrapperInfo,
        setTeethModelData,
        setTeethModelStepTransformation,
        onGlobalError,
        setTeethModelGeometry,
        setGingivaModelData,
        onFinish
    } = props

    const action = useStoreAction()
    const { changeMode } = appSliceReducer.actions

    return(
        <div id='file-loader'
            onMouseDown={(e)=>{e.stopPropagation()}}
        >
            <label className="button-load-file">
                Load 
                <input 
                    type="file"
                    multiple={true}
                    accept="application/zip"
                    style={{display: 'none'}} 
                    required
                    onChange={(e)=>{
                        if(e.target.files && e.target.files.length>1){

                            const zipFiles:File[]= [] 
                            for(let i = 0; i< e.target.files.length; i++){
                                const fileData:File = e.target.files[i]
                                zipFiles.push(fileData)
                            }

                            const zipPromisses:Promise<TObjData>[] = zipFiles.map((compressedFile:File)=>{
                                return(
                                    new Promise((resolve,reject)=>{

                                        JSZip.loadAsync(compressedFile)
                                        .then((openedArchive:JSZip)=>{
                                            const zipFormat = detectZipFormat(openedArchive)
            
                                            switch(zipFormat){
                                                case 'obj': {
                                                    
                                                    Object
                                                    .entries(openedArchive.files)
                                                    .map(item => item[1])
                                                    .map((fileItem, index)=>{
                                                        return(
                                                            fileItem.async('text')
                                                            .then((unzippedFile)=>{
                                                                resolve({
                                                                    name: fileItem.name,
                                                                    data: unzippedFile
                                                                } as TObjData)
                                                            })
                                                            .catch((e)=>{
                                                                reject('Cant unzip file')
                                                                onGlobalError("Cant unzip file.")
                                                                console.error("Cant unzip file")
                                                            })
                                                        )
                                                    })
                                                } break
                        
                                                default:{
                                                    onGlobalError("Zip file have unknown format")
                                                    console.error("Zip file have unknown format")
                                                } break
                                            }
                                        })
                                        .catch((err)=>{
                                            reject('Cant unzip file')
                                        })
                                    })
                                )
                                
                            })

                            const teethModelData:TSteppedModelData = {
                                upperSteps: [],
                                lowerSteps: []
                            }
                        
                            const gingivasModelData:TSteppedModelData = {
                                upperSteps: [],
                                lowerSteps: []
                            }

                            Promise.all(zipPromisses)
                            .then((objData)=>{

                                const sortedObjData = objData.sort((a,b)=>{
                                    return(a.name.localeCompare(b.name))
                                })
                                for(let i = 0; i< sortedObjData.length; i++){
                                    const parsedObj:THREE.Group = new OBJLoader().parse( sortedObjData[i].data )
                                    // COLLECT TEETH 3D-OBJECTS
                                    const upperTeeths:THREE.BufferGeometry[] = []
                                    const lowerTeeths:THREE.BufferGeometry[] = []
                                    
                                    let gingivaUpper:THREE.BufferGeometry | undefined 
                                    let gingivaLower:THREE.BufferGeometry | undefined 
                        
                                    parsedObj.traverse((objItem:any)=>{
                                        if(objItem.geometry){
                                            
                                            objItem.geometry.name = objItem.name
                        
                                            if((objItem.name.indexOf('teeth')>-1 ||
                                                objItem.name.indexOf('Tooth')>-1
                                            )&&(
                                                objItem.name.indexOf('_1')>-1 ||
                                                objItem.name.indexOf('_2')>-1 ||
                                                objItem.name.indexOf(' 1')>-1 ||
                                                objItem.name.indexOf(' 2')>-1     
                                            )){
                                                upperTeeths.push(objItem.geometry)
                                            }
                        
                                            if((objItem.name.indexOf('teeth')>-1 ||
                                                objItem.name.indexOf('Tooth')>-1
                                            )&&(
                                                objItem.name.indexOf('_3')>-1 ||
                                                objItem.name.indexOf('_4')>-1 ||
                                                objItem.name.indexOf(' 3')>-1 ||
                                                objItem.name.indexOf(' 4')>-1     
                                            )){
                                                
                                                lowerTeeths.push(objItem.geometry)
                                            }
                        
                                            if(objItem.name.indexOf('Mandible')>-1){
                                                gingivaLower = objItem.geometry
                                            }
                        
                                            if(objItem.name.indexOf('Maxilla')>-1){
                                                gingivaUpper = objItem.geometry
                                            }
                                        }
                                    })
                        
                                    const mergedUpperTeethsGeometry = mergeBufferGeometries(upperTeeths)
                                    const mergedLowerTeethsGeometry = mergeBufferGeometries(lowerTeeths)
                        
                                    teethModelData.upperSteps.push({
                                        name: 'Teeths_upper',
                                        data: mergedUpperTeethsGeometry
                                    })
                        
                                    teethModelData.lowerSteps.push({
                                        name: 'Teeths_lower',
                                        data: mergedLowerTeethsGeometry
                                    })
                        
                                    gingivasModelData.lowerSteps.push({
                                        name: 'Gingiva_lower',
                                        data: gingivaLower ? gingivaLower : new THREE.BufferGeometry()
                                    })
                        
                                    gingivasModelData.upperSteps.push({
                                        name: 'Gingiva_upper',
                                        data: gingivaUpper ? gingivaUpper : new THREE.BufferGeometry()
                                    })
                        
                                }
                        
                                if(
                                    (
                                        gingivasModelData.lowerSteps.length === 0 ||
                                        gingivasModelData.upperSteps.length === 0 
                                    ) ||
                                    (
                                        teethModelData.lowerSteps.length === 0 ||
                                        teethModelData.upperSteps.length === 0
                                    )
                                ){
                        
                                    onGlobalError('ZIP-file content is empty')
                                }else{

                                    setTeethModelGeometry(teethModelData)
                                    setGingivaModelData(gingivasModelData)
                                    setTimeout(()=>{
                                        action( changeMode( 'event_play_view' ) )
                                    },500)
                                }
                        
                                if( onFinish ){
                                    onFinish()
                                }
                                
                            })
                            .catch(()=>{
                                onGlobalError("Cant parse OBJ from zip-files")
                                console.error("Cant parse OBJ from zip-files")
                            })

                        }else if(e.target.files && e.target.files[0]){

                            const fileData:File = e.target.files[0]
                            const zip = new JSZip()
                            zip.loadAsync(fileData)
                            .then((fileContent:JSZip) => {
                                
                                const zipFormat = detectZipFormat(fileContent)
                                
                                switch(zipFormat){
                                    
                                    case 'obj': {
                                        unzipObj(
                                            fileContent,
                                            {
                                                url: '',
                                                setGingivaModelData: setGingivaModeGeometry,
                                                setTeethModelData,
                                                setTeethModelStepTransformation,
                                                setSmilewrapperInfo,
                                                onGlobalError
                                            },
                                            ()=>{
                                                setTimeout(()=>{
                                                    action( changeMode( 'event_play_view' ) )
                                                },500)
                                            }
                                        )
                                    } break

                                    case 'smilewrapper/ctm' :{
                                        unzipSmilewrapper(
                                            fileContent,
                                            {
                                                url: '',
                                                setGingivaModelData: setGingivaModeGeometry,
                                                setTeethModelData,
                                                setTeethModelStepTransformation,
                                                setSmilewrapperInfo,
                                                onGlobalError,
                                                onFinish: ()=>{
                                                    setTimeout(()=>{
                                                        action( changeMode( 'event_play_view' ) )
                                                    },500)
                                                }
                                            }
                                        )
                                    } break

                                    default:{
                                        onGlobalError("Zip file have unknown format")
                                        console.error("Zip file have unknown format")
                                    }break
                                }

                                
                            })
                            .catch((e)=>{
                                onGlobalError("Can't unzip local file")
                                console.error("Can't unzip local file")
                            })
                        }
                    }}
                />
            </label>
        </div>
    )
}

export default ZipFileLoader
