import packageInfo from '../../package.json'

export type TInfoProps = {
    smilewrapperInfo?: string
    className?: string
}

const Information = (props:TInfoProps) =>{

    const { smilewrapperInfo, className } = props

    let splittedInfo = String(smilewrapperInfo).split('-') // UUID
    
    let localCaseId = smilewrapperInfo ? ( 
            splittedInfo.length === 5 || splittedInfo.length === 4 ?  // for version uuid.v4 or uuid.v1
                splittedInfo[splittedInfo.length-1] 
            : 
                String(smilewrapperInfo) 
        ) 
    : 
        '-'


    return(
        <div className={className ? className :'information'}>
            <div className='info-wrapper'
                style={{
                    bottom: localCaseId !== '-' ?  '36px':'18px'
                }}
            >
                <div className='info-line'>
                    <div className='value'>
                        { packageInfo.version.replace('-', '.') } js
                    </div>
                </div>
                {
                    localCaseId !== '-' ?
                        <div className='info-line'>
                            <div className='value'>
                                { localCaseId }
                            </div>
                        </div>
                    : null
                }
            </div>
        </div>
    )
}

export default Information
