import { Dispatch, SetStateAction } from "react"
import { TApplicationActiveTab } from "../JsTpviewer"
import CompareIcon from "./icons/CompareIcon"
import MirrorIcon from "./icons/MirrorIcon"
import { useTypedSelector } from "../Store/Hook/useTypedSelector"
import { useStoreAction } from "../Store/Hook/useStoreAction"
import appSliceReducer from "../Store/Reducer/appReducer"

type TSwitchBeforeAfterProps = {
    activeTab: TApplicationActiveTab
    setActiveTab:(value: React.SetStateAction<TApplicationActiveTab>) => void
    setStepIndex:(value: React.SetStateAction<number>) => void
}

const SwitchBeforeAfter = (props: TSwitchBeforeAfterProps) => {
    
    const { 
        setActiveTab, 
        setStepIndex, 
        activeTab,
    } = props

    const { isViewMirrored } = useTypedSelector(state=>state.app)
    const action = useStoreAction()
    const { setMirrored } = appSliceReducer.actions

    return(
        <div id='compare-before-after'>
            
            <div className='button-flex-wrapper'>
                
                
                <div className='text'>
                    <CompareIcon/> Before & After
                </div>

                <label className="switch">
                    <input 
                        type="checkbox" 
                        checked={ activeTab === 'BEFORE_AFTER' ? true : false }
                        onChange = {(e) => {
                            if(activeTab=== 'BEFORE_AFTER'){
                                setActiveTab('TIMELINE')
                                setStepIndex(0)
                            }else{
                                setActiveTab('BEFORE_AFTER')
                            }
                        }}
                    />
                    <span className="slider round"/>
                </label>
            </div>

            <div className='button-flex-wrapper'>
                
                <div className='text'>
                    <MirrorIcon />
                    Mirror Image
                </div>
                <label className="switch">
                <input 
                    type="checkbox" 
                    checked={ isViewMirrored }
                    onChange = {(e) => {
                        action(  setMirrored(!isViewMirrored) )
                    }}
                />
                <span className="slider round"/>
            </label>
            </div>
        </div>
    )
}

export default SwitchBeforeAfter
