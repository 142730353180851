import {useEffect, useState} from "react"
import WasmTpviewer from "./WasmTpviewer"
import JsTpviewer from "./JsTpviewer"
import GlobalError from "./Ui/GlobalError"
import axios from 'axios'
import detectEnvironment from "./Utils/detectEnvironment"
import Preloader from "./Ui/Preloader"



type TpviewerImplementation = 'js' | 'wasm'

let assetUrls:string[] = []

const getImplSearchParam = (): TpviewerImplementation | undefined => {
    const impl = new URL(window.location.href).searchParams.get('impl')
    if (impl === 'js' || impl === 'wasm') {
        return impl as TpviewerImplementation
    }
    return undefined
}

const getCaseIdParam = (): string | undefined => {
    const caseId = new URL(window.location.href).searchParams.get('id')
    if(caseId){
        return caseId
    }
    return undefined
}

const TpviewerSwitcher = () => {
    const [ tpviewerImplementation, setTpviewerImplementation] = useState<TpviewerImplementation>(getImplSearchParam() || 'wasm')
    const [ isAssetUrlsRequested  , setAssetUrlsRequested    ] = useState<boolean>(false)
    const [ errorGlobalMessage, setErrorGlobalMessage ] = useState<string>('')

    const onWasmCrash = () => {
        console.warn('Switching to js implementation')
        setTpviewerImplementation('js')
    }
    
    const onGlobalError = (errorString:string)=>{ 
        setErrorGlobalMessage(errorString) 
    }
    
    useEffect(() => {
        (window as any).onGlobalError = onGlobalError;
        (window as any).assetUrls     = assetUrls    ;
    },[])

    useEffect(()=>{
        if(isAssetUrlsRequested === false){
            const caseId = getCaseIdParam()
            if(caseId){
                axios({
                    method: 'post',
                    url: `https://gateway.tp-${detectEnvironment()}.smiledirect.services/treatmentplan-asset-proxy/v1/assets`,
                    headers:{
                        'content-type': 'application/json',
                    },
                    data: {
                        "id": caseId,
                        "requestType": "TreatmentPlan"
                    },
                })
                .then(function(response) {
                    (window as any).assetUrls = response.data.compressedFiles as string[]
                })
                .catch(function(error) {
                    console.log( 'Asset not received', error )
                    onGlobalError('Asset not received')
                })
                .finally(()=>{
                    setAssetUrlsRequested(true)
                })
            }else{
                setAssetUrlsRequested(true)
                console.log( 'Asset urls was not requested')
            }
        }
    },[])

    useEffect(() => {
        (window as any).onGlobalError = onGlobalError
        
    },[])
    
    return(

        <>
            {   // wait for assetUrls will be recieived
                !isAssetUrlsRequested &&
                <Preloader/>
            }

            {

                isAssetUrlsRequested &&
                <>
                    {
                        errorGlobalMessage === '' ?
                        <>
                            {
                                tpviewerImplementation === 'wasm' && 
                                    <WasmTpviewer 
                                        onCrash       = { onWasmCrash   }
                                    />
                            }

                            {
                                tpviewerImplementation === 'js' &&
                                    <JsTpviewer
                                        onGlobalError = { onGlobalError }
                                    />
                            }
                        </>

                        : <GlobalError/>
                    }
                </>
            }
        </>

        
    ) 
}

export default TpviewerSwitcher
