import ReactDOM from 'react-dom/client'
import './index.sass'
import TpviewerSwitcher from "./TpviewerSwitcher"
import { Provider as StoreProvider } from 'react-redux' 
import store from './Store'
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)
root.render(
    <StoreProvider store={store} >
        <TpviewerSwitcher/>
    </StoreProvider>
)

