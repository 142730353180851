import { createSlice } from "@reduxjs/toolkit"
import { TAppStore } from '../../types'

import appChangeMode          from "../Action/appChangeMode"
import appIsInteracted        from "../Action/appIsInteracted"
import appSetMirrored         from "../Action/appSetMirrored"

const appInitialState:TAppStore = {
    mode             : 'loading',
    isInteracted     : false,
    isViewMirrored   : false,
}

const appSliceReducer = createSlice({
    name: 'app',
    initialState: appInitialState,
    reducers: {
        changeMode                        : appChangeMode,
        setInteracted                     : appIsInteracted,
        setMirrored                       : appSetMirrored,
    },
})

export default appSliceReducer
