import { useFrame, useThree } from "@react-three/fiber"
import { MutableRefObject, useEffect, useRef } from "react"
import { TViewValue } from "../types"
import * as THREE from 'three'
import { TApplicationActiveTab, TModelStateAccumulator, TViewResetFlag, cameraDistance } from "../JsTpviewer"
import getZoomValue from "../Utils/get-viewport-zoom-value"

export type TModelViewController = {
    activeTab?: TApplicationActiveTab
    delta: MutableRefObject<{
        x: number
        y: number
    }> 
    isMouseDown: MutableRefObject<boolean>
    isMouseRightDown: MutableRefObject<boolean>
    currentView: TViewValue
    isNeedToResetViewRef: React.MutableRefObject<TViewResetFlag>
    viewType: 'main' |  'before' | 'after'
    modelStateAccumulatorRef: React.MutableRefObject<TModelStateAccumulator>
    isViewMirrored : boolean
}

type TViewRotationValue = {
    [key:string]:THREE.Euler
}

export const viewBaseRotation:TViewRotationValue = {
    'front' : new THREE.Euler(         0,          0,   0 ),
    'top'   : new THREE.Euler(-Math.PI/2,          0,   0 ),
    'bottom': new THREE.Euler( Math.PI/2,          0,   0 ),
    'left'  : new THREE.Euler(         0, -Math.PI/2,   0 ),
    'right' : new THREE.Euler(         0,  Math.PI/2,   0 ),
}


export const getBaseRotation = (currentView: TViewValue):THREE.Euler =>{
    switch(currentView){
        case 'top':
            return viewBaseRotation.top
            
        case 'bottom':
            return viewBaseRotation.bottom
            
        case 'left':
            return  viewBaseRotation.left
            
        case 'right':
            return viewBaseRotation.right

        default:
            return  viewBaseRotation.front
    }
}

const ModelViewController = (props:TModelViewController) =>{
    
    const { scene } = useThree(state => state)

    const {
        activeTab,
        delta,
        isMouseDown,
        isMouseRightDown,
        currentView,
        isNeedToResetViewRef,
        viewType,
        modelStateAccumulatorRef,
        isViewMirrored,
    } = props

    const  prevMouseDownRef = useRef(false)

    const  prevModelsRotationRef    = useRef(new THREE.Euler())
    const  modelsSummaryRotationRef = useRef(new THREE.Euler())

    const  prevModelsPositionRef    = useRef(new THREE.Vector3())
    const  modelsSummaryPositionRef = useRef(new THREE.Vector3())

    const isNeedUpdate = useRef(true)
    const isNeedMirrorUpdate = useRef(true)

    useEffect(()=>{
        isNeedUpdate.current = true 
    },[currentView])

    useEffect(()=>{
        isNeedMirrorUpdate.current = true 
    },[isViewMirrored])

    useFrame(({gl,camera}) => {

        // mirror objects in view
        if(isNeedMirrorUpdate.current === true){
            isNeedMirrorUpdate.current = false
            if(isViewMirrored){
                scene.children
                .filter((sceneObject)=>{
                    return( !(sceneObject.name.indexOf('light')>-1) )
                })
                .forEach((sceneObject)=>{
                    sceneObject.scale.set(-1,1,1)
                })
            }else{
                scene.children
                .filter((sceneObject)=>{
                    return( !(sceneObject.name.indexOf('light')>-1) )
                })
                .forEach((sceneObject)=>{
                    sceneObject.scale.set(1,1,1)
                })
            }
        }

        // UPDATE CAMERA POSITION ON CHANGE VIEW (frol/left/.. etc)
        if(isNeedUpdate.current === true){
            isNeedUpdate.current = false
            camera.position.copy(new THREE.Vector3(0,0,cameraDistance))
            camera.setRotationFromEuler(new THREE.Euler(0,0,0))

            prevModelsRotationRef.current.copy(getBaseRotation(currentView))
            prevModelsPositionRef.current.copy(new THREE.Vector3())
            
            modelsSummaryRotationRef.current = new THREE.Euler()
            modelsSummaryPositionRef.current = new THREE.Vector3()

            

            // Sync additional position and rotation with another tab ( Timeline or Before/after ) 
            if( activeTab === 'BEFORE_AFTER'){

                if(modelStateAccumulatorRef.current && typeof(modelStateAccumulatorRef.current['before'])!=='undefined' ){

                    prevModelsPositionRef.current.copy(modelStateAccumulatorRef.current['before'].position)
                    prevModelsRotationRef.current.copy(modelStateAccumulatorRef.current['before'].rotation)
                    modelStateAccumulatorRef.current['before'] = undefined

                }else if(modelStateAccumulatorRef.current && typeof(modelStateAccumulatorRef.current['after'])!=='undefined'){

                    prevModelsPositionRef.current.copy(modelStateAccumulatorRef.current['after'].position)
                    prevModelsRotationRef.current.copy(modelStateAccumulatorRef.current['after'].rotation)
                    modelStateAccumulatorRef.current['after'] = undefined

                }else{
                    
                    prevModelsRotationRef.current.copy(getBaseRotation(currentView))
                    prevModelsPositionRef.current.copy(new THREE.Vector3())
                }

            }else{
                // ACTIVE TAB === undefined ( TIMELINE MODE )
                if(modelStateAccumulatorRef.current && typeof(modelStateAccumulatorRef.current['main'])!=='undefined'){
                    
                    prevModelsPositionRef.current.copy(modelStateAccumulatorRef.current['main'].position)
                    prevModelsRotationRef.current.copy(modelStateAccumulatorRef.current['main'].rotation)
                    modelStateAccumulatorRef.current['main'] = undefined
                }else{
                    
                    prevModelsRotationRef.current.copy(getBaseRotation(currentView))
                    prevModelsPositionRef.current.copy(new THREE.Vector3())
                }
            }
        }

        // ON View RESET ( click again on the same view-button in UI)
        if(isNeedToResetViewRef && typeof(isNeedToResetViewRef.current)!=='undefined'){
            if(isNeedToResetViewRef.current[viewType] === true){
                
                isNeedToResetViewRef.current[viewType] = false

                camera.position.copy(new THREE.Vector3(0,0,cameraDistance))
                camera.setRotationFromEuler(new THREE.Euler(0,0,0))
                camera.zoom = getZoomValue(activeTab)
                camera.updateProjectionMatrix()
                prevModelsRotationRef.current.copy(getBaseRotation(currentView))
                prevModelsPositionRef.current.copy(new THREE.Vector3())
                
                modelsSummaryRotationRef.current = new THREE.Euler()
                modelsSummaryPositionRef.current = new THREE.Vector3()

            }
        }

        // On Mouse Down / Touch Start
        if( prevMouseDownRef.current === false && isMouseDown.current === true ){
            prevMouseDownRef.current = true
        }

        // rotate models on Mouse Move / Touch Move
        if( prevMouseDownRef.current === true && isMouseDown.current === true && isMouseRightDown.current === false){
            
            const drX = (delta.current.x / 10000)*180/Math.PI // rotation in radians
            const drY = (delta.current.y / 10000)*180/Math.PI

            if(
                currentView === 'front' ||
                currentView === 'left'  || 
                currentView === 'right'
            ){
                modelsSummaryRotationRef.current = new THREE.Euler( -drY, -drX  , 0   )
            }else if(currentView === 'top'){
                modelsSummaryRotationRef.current = new THREE.Euler( -drY, 0     , -drX)
            }else if(currentView === 'bottom'){
                modelsSummaryRotationRef.current = new THREE.Euler( -drY, 0     ,  drX)
            }
            modelsSummaryPositionRef.current = new THREE.Vector3()
            scene.children
            .filter((sceneObject)=>{
                return( !(sceneObject.name.indexOf('light')>-1) )
            })
            .forEach((sceneObject)=>{
                sceneObject.rotation.set(
                    prevModelsRotationRef.current.x + modelsSummaryRotationRef.current.x,
                    prevModelsRotationRef.current.y + modelsSummaryRotationRef.current.y,
                    prevModelsRotationRef.current.z + modelsSummaryRotationRef.current.z,
                )
            })
        }
        
        // pan models on Mouse Move / Touch Move
        if(prevMouseDownRef.current === true && isMouseDown.current === true && isMouseRightDown.current === true){
            
            const dX = delta.current.x/7  // rotation in radians
            const dY = delta.current.y/7

            modelsSummaryPositionRef.current = new THREE.Vector3( isViewMirrored ? dX : -dX ,dY,0)
            modelsSummaryRotationRef.current = new THREE.Euler()
            
            scene.children
            .filter((sceneObject)=>{
                return( !(sceneObject.name.indexOf('light')>-1) )
            })
            .forEach((sceneObject)=>{
                sceneObject.position.set(
                    prevModelsPositionRef.current.x + modelsSummaryPositionRef.current.x,
                    prevModelsPositionRef.current.y + modelsSummaryPositionRef.current.y,
                    prevModelsPositionRef.current.z + modelsSummaryPositionRef.current.z,
                )
            })
        }
        // On Mouse Up / Touch End
        if(prevMouseDownRef.current === true && isMouseDown.current === false){
            prevMouseDownRef.current = false
            prevModelsRotationRef.current = new THREE.Euler(
                prevModelsRotationRef.current.x + modelsSummaryRotationRef.current.x,
                prevModelsRotationRef.current.y + modelsSummaryRotationRef.current.y,
                prevModelsRotationRef.current.z + modelsSummaryRotationRef.current.z,
            )
            prevModelsPositionRef.current = new THREE.Vector3(
                prevModelsPositionRef.current.x + modelsSummaryPositionRef.current.x,
                prevModelsPositionRef.current.y + modelsSummaryPositionRef.current.y,
                prevModelsPositionRef.current.z + modelsSummaryPositionRef.current.z,
            )
            modelsSummaryPositionRef.current = new THREE.Vector3()
            modelsSummaryRotationRef.current = new THREE.Euler()

            
        }

        // If nothinf pressed
        if(prevMouseDownRef.current === false && isMouseDown.current === false && isMouseRightDown.current === false){
            scene.children
            .filter((sceneObject)=>{
                return( !(sceneObject.name.indexOf('light')>-1) )
            })
            .forEach((sceneObject)=>{
                sceneObject.rotation.set(
                    prevModelsRotationRef.current.x,
                    prevModelsRotationRef.current.y,
                    prevModelsRotationRef.current.z,
                )
                sceneObject.position.set(
                    prevModelsPositionRef.current.x,
                    prevModelsPositionRef.current.y,
                    prevModelsPositionRef.current.z,
                )
            })
            // SAVE additional position and rotation for sync in another tab (Timeline or Before/After)
            if(modelStateAccumulatorRef && modelStateAccumulatorRef.current){
                if(viewType === 'main'){
    
                    modelStateAccumulatorRef.current['before'] = {
                        position: new THREE.Vector3().copy(prevModelsPositionRef.current),
                        rotation: new THREE.Euler().copy(prevModelsRotationRef.current),
                    }
                    
                    modelStateAccumulatorRef.current['after'] = {
                        position: new THREE.Vector3().copy(prevModelsPositionRef.current),
                        rotation: new THREE.Euler().copy(prevModelsRotationRef.current),
                    }
    
                }else{
    
                    modelStateAccumulatorRef.current['main'] = {
                        position: new THREE.Vector3().copy(prevModelsPositionRef.current),
                        rotation: new THREE.Euler().copy(prevModelsRotationRef.current),
                    }
                }
            }
        }


    })

    return(<></>)
}

export default ModelViewController
