import { configureStore } from '@reduxjs/toolkit'
import appSliceReducer from './Reducer/appReducer'

const store = configureStore({
    reducer: {
        app      : appSliceReducer.reducer,
    },
})

export default store

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
