import { useMemo, useState, useEffect, useRef } from 'react'
import * as THREE from 'three'
import ModelLoader, { TTeethStepsPosition } from "./ModelLoader/ModelLoader"
import { TSteppedModelData, TTeethModelData, TViewValue } from './types'
import Preloader from './Ui/Preloader'
import getZoomValue from './Utils/get-viewport-zoom-value'
import useInterval from './Utils/use-interval'
import View3dTimeline from './Ui/View3dTimeline'
import View3dBeforeAfter from './Ui/View3dBeforeAfter'
import ZipFileLoader from './Ui/ZipFileLoader'
import ModelFromFromGeometry from './Scene/ModelFromFromGeometry'
import Helper from './Utils/Helper'
import getCtmTransformedGeometry from './ModelLoader/getCtmTransformedGeometry'
import getCasesArrayFromUrl from './Utils/getCasesFromUrl'
import { useTypedSelector } from './Store/Hook/useTypedSelector'
import { useStoreAction } from './Store/Hook/useStoreAction'
import appSliceReducer from './Store/Reducer/appReducer'

export const delayValue = 700
export const delayPauseValue = 500
export const delayToChangeViewValue = 10
export const cameraDistance = 500

export type TModelState = {
    position : THREE.Vector3
    rotation : THREE.Euler
}

export type TModelStateAccumulator = {
    main  : TModelState | undefined
    before: TModelState | undefined
    after : TModelState | undefined
}

export type TViewResetFlag = {
    main  : boolean
    before: boolean
    after : boolean
}

export type TViewBeforeAfter = "" | "BEFORE" | "AFTER"

export type TApplicationActiveTab = 'TIMELINE' | 'BEFORE_AFTER'

type TJsTpviewerProps = {
    onGlobalError: (errorString:string) => void
}

const JsTpviewer = (props: TJsTpviewerProps) => {

    
    const { app } = useTypedSelector(state=>state)
    const action = useStoreAction()
    const { changeMode, setInteracted } = appSliceReducer.actions

    const { onGlobalError } = props

    const casesUrlArray = getCasesArrayFromUrl()

    const [ activeTab, setActiveTab] = useState<TApplicationActiveTab>('TIMELINE')

    const prevTabRef = useRef<TApplicationActiveTab>('TIMELINE')
    
    const viewForAutoPlay:TViewValue[] = ['front', 'top','bottom','left','right']

    const [ viewIndex                    , setViewIndex                    ] = useState(0)
    const [ tpStepIndex                  , setTpStepIndex                  ] = useState(0)
    const [ animationLoopCounter         , setAnimationLoopCounter         ] = useState(0)
    const [ currentView                  , setCurrentView                  ] = useState<TViewValue>('front')

    const [ teethModelStepTransformation , setTeethModelStepTransformation ] = useState<TTeethStepsPosition[][]>([])
    
    const [ delayModelAnimation , setDelayModelAnimation ] = useState<null|number>(null) // interval size
    const [ delayPause          , setDelayPause          ] = useState<null|number>(null) // interval size
    const [ delayToChangeView   , setDelayToChangeView   ] = useState<null|number>(null) // interval size

    const modelStateAccumulatorRef   = useRef<TModelStateAccumulator>({
        main   : undefined,
        after  : undefined,
        before : undefined,
    })

    const isNeedToResetViewRef = useRef<TViewResetFlag>({
        main   : false,
        after  : false,
        before : false,
    })
    const cameraPosition = useRef({
        position: new THREE.Vector3(0,0,100),
        zoom: getZoomValue(),
    })

    useEffect(()=>{
        if( casesUrlArray.length>0 ){
            action( changeMode( 'event_loading_from_url' ) )
        }else{
            action( changeMode( 'event_loading_from_local_file' ) )
        }

        window.addEventListener('mousedown' ,()=>{ action( changeMode( 'event_stop' ) ) })
        window.addEventListener('wheel'     ,()=>{ action( changeMode( 'event_stop' ) ) })
        window.addEventListener('touchstart',()=>{ action( changeMode( 'event_stop' ) ) })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    const setCameraParameters = (x:number, y:number, z:number, zoom: number) =>{
        cameraPosition.current = {
            position: new THREE.Vector3(x,y,z),
            zoom
        }
    }

    // teeths have base geometry for all steps, but transformed for every step
    const [ teethModelData, setTeethModelData ] = useState<TTeethModelData>({
        upper: [],
        lower: [],
    })

    const [ teethModelGeometry, setTeethModelGeometry ] = useState<TSteppedModelData>({
        upperSteps: [],
        lowerSteps: []
    })

    // gingiva have own geometry for every step
    const [ gingivaModelGeometry, setGingivaModeGeometry ] = useState<TSteppedModelData>({
        upperSteps: [],
        lowerSteps: []
    })

    const [smilewrapperInfo, setSmilewrapperInfo         ] = useState<string | undefined>()


    useEffect(()=>{
        const teethsteppedGeometry:TSteppedModelData = {
            upperSteps: [],
            lowerSteps: []
        }

        // CTM SCENARIO : Base geometry + few transformations matrixes
        if(teethModelStepTransformation.length > 0){
            
            teethModelStepTransformation.forEach((stepTransformation, stepIndex)=>{
                
                teethsteppedGeometry.upperSteps.push({
                    name: `teeth-stage${stepIndex}-upper`,
                    data: getCtmTransformedGeometry( stepTransformation,teethModelData.upper )
                })
                
                teethsteppedGeometry.lowerSteps.push({
                    name: `teeth-stage${stepIndex}-lower`,
                    data: getCtmTransformedGeometry( stepTransformation,teethModelData.lower )
                })

            })

            setTeethModelGeometry(teethsteppedGeometry)
            
        }else if(teethModelStepTransformation.length === 0 && ( teethModelData.lower.length > 0 || teethModelData.upper.length >0 )){
            for(let i = 0; i<teethModelData.lower.length; i++){
                teethsteppedGeometry.upperSteps.push({
                    name: `teeth-stage${i}-upper`,
                    data: teethModelData.upper[i].data
                })
                
                teethsteppedGeometry.lowerSteps.push({
                    name: `teeth-stage${tpStepIndex}-lower`,
                    data: teethModelData.lower[i].data
                })
            }
            setTeethModelGeometry(teethsteppedGeometry)
        } 
        
        return(()=>{
            teethsteppedGeometry.lowerSteps = []
            teethsteppedGeometry.upperSteps = []
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[teethModelData, teethModelStepTransformation])
    


    // ANIMATION START
    useEffect(()=>{

        if(app.mode === 'play_view'){
            if(viewIndex === 0){
                setDelayModelAnimation(animationLoopCounter === 0 && app.isInteracted === false ? delayValue/2 : delayValue)
            }else{
                setDelayModelAnimation(delayValue)
            }
        }

        if(app.mode === 'stoped'){
            action(  setInteracted() )
            setDelayModelAnimation(null)
            setDelayPause(null)
            setDelayModelAnimation(null)
            setDelayToChangeView(null)
        }

        if(app.mode === 'pause_before_next_view'){
            setDelayPause(delayPauseValue)
            setDelayModelAnimation(null)
        }

        if(app.mode === 'changing_view'){
            
            if(viewIndex < viewForAutoPlay.length-1){
                // next view
                if(animationLoopCounter < 1 && app.isInteracted === false){
                    setViewIndex(viewIndex+1)
                    setCurrentView(viewForAutoPlay[viewIndex+1])
                }
            }else{
                // full loop of view is ended, go to start-view
                setViewIndex(0)
                setCurrentView(viewForAutoPlay[0])
                setAnimationLoopCounter(prev => prev+1)
            }
            setDelayToChangeView(delayToChangeViewValue)
            
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[app.mode])

    // solution for problem (press view button but autoplay doesn't get new view and play previous one )
    useEffect(()=>{
        if(viewForAutoPlay.indexOf(currentView) !== viewIndex){
            setViewIndex(viewForAutoPlay.indexOf(currentView))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[currentView])

    useInterval(()=>{
        if(app.mode === 'play_view'){
            if(tpStepIndex + 1 < gingivaModelGeometry.upperSteps.length){
                setTpStepIndex (tpStepIndex + 1)
            }else if(tpStepIndex === gingivaModelGeometry.upperSteps.length-1){

                if(viewIndex === 0 && animationLoopCounter === 1){ 
                    
                    action( changeMode( 'event_stop' ) )
                }else{

                    // animation will be played on first loop only
                    action( changeMode( 'event_pause_before_next_view' ) )
                }
            }

        }
    },delayModelAnimation)

    useInterval(()=>{
        if(animationLoopCounter >= 0 ){
            
            if(app.isInteracted === false){
                action( changeMode( 'event_change_view' ) )
                setTpStepIndex (0)
            }else{
                action( changeMode( 'event_stop' ) )
            }
        }else{
            
            action( changeMode( 'event_stop' ) )
        }
        setDelayPause(null)
    }, delayPause)

    useInterval(()=>{   
        action( changeMode( 'event_play_view' ) )
        setDelayToChangeView(null)
    }, delayToChangeView)

    // ANIMATION END


    useEffect(()=>{
        if(
            app.mode === 'play_view' ||
            app.mode === 'pause_before_next_view' ||
            app.mode === 'changing_view'
        ){
                action( changeMode( 'event_stop' ) )
                
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[activeTab])



    const teethsModels = useMemo(()=>{ 
        return( // FOR TIMELINE
        <ModelFromFromGeometry
            modelType='TEETHS'
            modelsGeometry={teethModelGeometry}
            stepIndex = {tpStepIndex}
            currentView = { currentView }
        />
    )},[teethModelGeometry, tpStepIndex, currentView])

    const teethsModelsBefore = useMemo(()=>{ return(
        <ModelFromFromGeometry
            modelType='TEETHS'
            modelsGeometry={teethModelGeometry}
            stepIndex = {0}
            currentView = { currentView }
        />
    )},[teethModelGeometry, currentView])


    const teethsModelsAfter = useMemo(()=>{return(
        <ModelFromFromGeometry
            modelType='TEETHS'
            modelsGeometry={teethModelGeometry}
            stepIndex = {teethModelGeometry.upperSteps.length-1 }
            currentView = { currentView }
        />
    )},[teethModelGeometry, currentView])

    const gingivaModels = useMemo(()=>{ return(
        <ModelFromFromGeometry
            modelType='GINGIVA'
            modelsGeometry={gingivaModelGeometry}
            stepIndex = {tpStepIndex }
            currentView = { currentView }
        />
    )},[gingivaModelGeometry, tpStepIndex, currentView])


    const gingivaModelsBefore = useMemo(()=>{return(
        <ModelFromFromGeometry
            modelType='GINGIVA'
            modelsGeometry={gingivaModelGeometry}
            stepIndex = { 0 }
            currentView = { currentView }
        />
    )},[gingivaModelGeometry, currentView])

    const gingivaModelsAfter = useMemo(()=>{return(
        <ModelFromFromGeometry
            modelType='GINGIVA'
            modelsGeometry={gingivaModelGeometry}
            stepIndex = {gingivaModelGeometry.upperSteps.length-1 }
            currentView = { currentView }
        />
    )},[gingivaModelGeometry, currentView])

    return (
        <>
            {
                app.mode === 'loading' &&
                <Preloader/>
            }

            {
                app.mode === 'loading_from_url' &&
                <>
                    <Preloader/>
                    <ModelLoader 
                        url                              = { casesUrlArray                   }
                        setTeethModelData                = { setTeethModelData               }
                        setGingivaModelData              = { setGingivaModeGeometry          }
                        setTeethModelGeometry            = { setTeethModelGeometry           }         
                        setTeethModelStepTransformation  = { setTeethModelStepTransformation }
                        setSmilewrapperInfo              = { setSmilewrapperInfo             }
                        onGlobalError                    = { onGlobalError                   }
                    />
                </>
            }

            {
                app.mode === 'loading_from_local_file' && 
                <ZipFileLoader
                    setGingivaModeGeometry          = { setGingivaModeGeometry          }
                    setTeethModelData               = { setTeethModelData               }
                    setTeethModelStepTransformation = { setTeethModelStepTransformation }
                    setSmilewrapperInfo             = { setSmilewrapperInfo             }
                    onGlobalError                   = { onGlobalError                   }
                    setGingivaModelData             = { setGingivaModeGeometry          }
                    setTeethModelGeometry           = { setTeethModelGeometry           }
                />
            }

            {
                ( 
                    app.mode === 'stoped'                   ||
                    app.mode === 'play_view'              ||  
                    app.mode === 'changing_view'          ||
                    app.mode === 'pause_before_next_view' 
                ) && 
                <>  

                    {
                        activeTab==='TIMELINE' &&   
                            <View3dTimeline
                                gingivaModelGeometry     = { gingivaModelGeometry     }
                                gingivaModels            = { gingivaModels            }
                                teethsModels             = { teethsModels             }
                                currentView              = { currentView              } 
                                setCurrentView           = { setCurrentView           }
                                smilewrapperInfo         = { smilewrapperInfo         }
                                stepIndex                = { tpStepIndex              }
                                setStepIndex             = { setTpStepIndex           }
                                activeTab                = { activeTab                }
                                setActiveTab             = { setActiveTab             }
                                modelStateAccumulatorRef = { modelStateAccumulatorRef }
                                prevTabRef               = { prevTabRef               }
                                isNeedToResetViewRef     = { isNeedToResetViewRef     }

                            />
                    }


                    {
                        activeTab==='BEFORE_AFTER' &&
                            <View3dBeforeAfter
                                setCameraParameters      = { setCameraParameters      }
                                cameraPosition           = { cameraPosition           }
                                teethsModelsBefore       = { teethsModelsBefore       }
                                teethsModelsAfter        = { teethsModelsAfter        }
                                gingivaModelsBefore      = { gingivaModelsBefore      }
                                gingivaModelsAfter       = { gingivaModelsAfter       }
                                activeTab                = { activeTab                }
                                setActiveTab             = { setActiveTab             }
                                currentView              = { currentView              }
                                setCurrentView           = { setCurrentView           }
                                setStepIndex             = { setTpStepIndex           }
                                modelStateAccumulatorRef = { modelStateAccumulatorRef }
                                prevTabRef               = { prevTabRef               }
                                isNeedToResetViewRef     = { isNeedToResetViewRef     }
                                smilewrapperInfo         = { smilewrapperInfo         }
                            />
                    }
                </>
            }

            <Helper />
            
        </>
    )
}

export default JsTpviewer
